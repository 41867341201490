import { createStore, useStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { InferQueryOutput } from '#lib/trpc';
import { trpcClient } from '#lib/trpc';
import firebase from 'firebase';
import {
  createOffer,
  contracts,
  AssetProp,
  cancelOffer,
} from '@dropspot-io/contract-api';
import {
  CardanoWalletExtended,
  useCardanoWallet,
} from '../../../../lib/wallet/WalletContext';

import {
  Address,
  MintingPolicyHash,
  Program,
  TxId,
  TxOutput,
  UTxO,
  bytesToHex,
  hexToBytes,
  config,
  Tx,
} from '@hyperionbt/helios';
import constants from '#lib/constants';
import {
  BUILD_ACTION,
  getAddress,
  getNetworkParams,
  signTransaction,
  submitTx,
} from '#lib/plutus/DropspotMarketContract';
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
} from 'react';
import {
  CancelOfferTxHandlerSchemaType,
} from '../../../../pages/api/offers/offerTxHandler';
import getAbsoluteURL from '../../../../lib/getAbsoluteUrl';

import { getAssetOffersForBalance, getCollectionOffersForBalance, getMaestroTransaction, getMyOffers } from '#lib/firestore';
import { OfferRecord } from '#types/index';

export type OfferResponse = InferQueryOutput<'offers'>[number];
export type OfferResponseCollectionAssetOffers =
  InferQueryOutput<'collection-asset-offers'>[number];

export type AcceptOfferInputs = {
  offer: OfferResponse;
  token: {
    policy: string;
    tokenName: string;
    quantity: number;
  };
  offerLovelace: number;
};
config.IS_TESTNET = constants.TESTNET;
type OffersStore = {
  offersListeners: (() => void)[];
  collectionListener: (() => void);
  policy: string;
  tokenName?: string;
  wallet?: CardanoWalletExtended;
  walletAddresses?: string[];
  setWallet: (wallet: CardanoWalletExtended) => void;
  allOffers: Map<'Collection' | 'Asset', Map<string, OfferRecord>>;
  allCollectionAssetOffers: OfferResponseCollectionAssetOffers[];
  setTokenName: (tokenName: string) => void;
  // acceptOffer: (offer: AcceptOfferInputs) => Promise<string>;
  cancelOffer: (offer: OfferResponse) => Promise<string>;
  createOffer: (offer: AssetProp, offerExpiry: number) => Promise<string>;
  refresh: () => Promise<void>;
  txState?: BUILD_ACTION;
  collectionStats?: {
    collectionOfferLiquidity: number;
    assetOfferLiquidity: number;
    allPolicyLiquidity: number;
  };
};

const createOfferStore = (policy: string, tokenName = '') => {

  const program = Program.new(contracts.offerContract, [
    contracts.superCubeModule,
  ]);
  program.parameters = {
    'super_cube::DS_ADDRESS': Address.fromBech32(
      constants.NEXT_PUBLIC_DROPSPOT_ADDRESS
    ).pubKeyHash,
    'super_cube::DS_REDUCTION_POLICY': MintingPolicyHash.fromHex(
      constants.SUPER_CUBE_POLICY
    ),
  };
  const compiled = program.compile(true);

  const store = createStore<OffersStore, [['zustand/devtools', OffersStore]]>(
    devtools<OffersStore>(
      (set, get) => {
        return {
          offersListeners: [],
          collectionListener: () => null,
          policy,
          tokenName: '',
          allOffers: new Map(),
          allCollectionAssetOffers: [],

          setTokenName: (tokenName: string) => {
            console.log('In setTokenName', get().tokenName, tokenName);
            if (get().tokenName === tokenName) return;
            set({ tokenName });

            get().offersListeners.forEach(l => l());

            console.log('Get Offers', policy, tokenName);
            const listener = getAssetOffersForBalance([`${policy}.${tokenName}`], (offers) => {
              console.log('>>> Offers', offers);
              const allAssetOffers = new Map();

              offers.forEach(offer => allAssetOffers.set(`${offer.tx_hash}.${offer.index}`, offer));

              const allOffers = get().allOffers;
              allOffers.set('Asset', allAssetOffers);

              set({ allOffers: new Map(allOffers) });
              calcStats();
            });

            set({ offersListeners: [listener] });
          },
          refresh: async () => {
            return;
          },
          setWallet: (wallet) => {
            if (store.getState().wallet === wallet) return;
            store.setState({ wallet });

            Promise.all([
              wallet.getUnusedAddresses(),
              wallet.getUsedAddresses(),
            ])
              .then((addresses) => {
                const walletAddresses = [...addresses[0], ...addresses[1]];
                console.log('In: walletAddresses', walletAddresses.length);
                store.setState({
                  walletAddresses: walletAddresses.map((hexAddr) =>
                    Address.fromHex(hexAddr).toBech32()
                  ),
                });
              })
              .catch(console.error);
          },
          // acceptOffer: async (offer) => {
          //   const wallet = get().wallet;
          //   if (!wallet) throw new Error('No wallet');

          //   return processOfferAccept(
          //     wallet,
          //     offer,
          //     bytesToHex(compiled.toCbor()),
          //     (action) => {
          //       store.setState({ txState: action });
          //     }
          //   );
          // },
          cancelOffer: async (offer: OfferResponse) => {
            const wallet = get().wallet;

            if (!wallet) throw new Error('No wallet');

            const utxo = await trpcClient.query('maestro-utxo', {
              txHash: offer.tx_hash,
              index: offer.tx_index,
            });

            const offerUtxo = new UTxO(
              TxId.fromHex(utxo.tx_hash),
              BigInt(utxo.index),
              TxOutput.fromCbor(hexToBytes(utxo.txout_cbor))
            );

            if (
              offerUtxo.origOutput.address.validatorHash.hex ==
              constants.OFFER_VALIDATOR_HASH[0]
            ) {
              const currentUser = firebase.auth().currentUser;
              if (!currentUser) return '';

              const address = await getAddress(wallet);

              const utxos = await wallet.getUtxos();
              if (!utxos) throw new Error('Wallet has no UTxOs available');

              const data: CancelOfferTxHandlerSchemaType = {
                walletAddress: address,
                offerUTxO: offerUtxo.toCborHex(),
                action: 'cancel',
                network: constants.TESTNET ? 'preprod' : 'mainnet',
                walletUTxOs: utxos,
              };

              const url = getAbsoluteURL('/api/offers/offerTxHandler');

              const r = await fetch(url, {
                method: 'POST',
                headers: {
                  'content-type': 'application/json',
                  authorization: `bearer ${await currentUser.getIdToken()}`,
                },
                body: JSON.stringify(data),
              });

              if (!r.ok) {
                throw new Error('Tx Build Error');
              }

              const txCbor = await r.text();

              console.log('txCbor', txCbor);

              const tx = Tx.fromCbor(hexToBytes(txCbor));

              const signatures = await signTransaction(tx, wallet);
              tx.addSignatures(signatures);
              return submitTx(bytesToHex(tx.toCbor()), []);
            }

            return cancelOffer({
              wallet,
              scriptCbor: bytesToHex(compiled.toCbor()),
              offerUtxo,
              networkParams: await getNetworkParams(),
              submitTx: (tx) => submitTx(tx, []),
              listener: (action) => {
                console.log(action);
              },
            });
          },
          createOffer: async (
            offer: AssetProp,
            offerExpiry = Number.MAX_VALUE
          ) => {
            const wallet = get().wallet;

            if (!wallet) throw new Error('No wallet');
            return createOffer({
              scriptCbor: bytesToHex(compiled.toCbor()),
              wallet,
              asset: offer,
              expiry: offerExpiry,
              submitTx: (tx) => submitTx(tx, []),
              listener: (action) => {
                store.setState({ txState: action });
              },
              networkParams: await getNetworkParams(),
            });
          },
        };
      },
      {
        name: `OffersStore-${policy}-${tokenName}`,
      }
    )
  );

  function calcStats() {
    const policy = store.getState().policy;
    const allOffersFromStore = store.getState().allOffers;

    const allOffers: OfferRecord[] = [];

    allOffers.push(...allOffersFromStore.get('Asset')?.values() || [], ...allOffersFromStore.get('Collection')?.values() || []);

    const collectionOffers = allOffers
      .flatMap((offer) =>
        offer.details
          .filter((detail) => detail.type === 'Collection')
          .map((detail) => ({
            type: 'Collection',
            ...offer,
            detail: detail,
            index: offer.index,
            collection_policy: detail.policy
          }))
      )
      .filter((o) => o.collection_policy === policy);

    const assetOffers = allOffers
      .flatMap((offer) =>
        offer.details
          .filter((detail) => detail.type === 'Asset')
          .map((detail) => ({
            type: 'Asset',
            ...offer,
            detail: detail,
            collection_policy: detail.policy,
            token_name: detail.tokenName
          }))
      )
      .filter((o) => o.collection_policy === policy);


    function reducer(acc: number, cur: { detail: { offer: number } }): number {
      return acc + cur.detail.offer;
    }

    const collectionOfferLiquidity = collectionOffers.reduce(reducer, 0);
    const assetOfferLiquidity = assetOffers.reduce(reducer, 0);

    store.setState({
      collectionStats: {
        collectionOfferLiquidity,
        assetOfferLiquidity,
        allPolicyLiquidity: collectionOfferLiquidity + assetOfferLiquidity,
      },
    });
  }

  const collectionListener = getCollectionOffersForBalance([policy], (offers) => {
      console.log('>>> Offers: Collection', offers);

    const allOffersFromStore = store.getState().allOffers;

    const allCollectionOffers = new Map();

    offers.forEach(offer => allCollectionOffers.set(`${offer.tx_hash}.${offer.index}`, offer));

    store.setState({ allOffers: new Map(allOffersFromStore).set('Collection', allCollectionOffers) });

    calcStats();

  });

  store.setState({ collectionListener });

  if (tokenName) {
    store.getState().setTokenName(tokenName);
  }

  return store;
};

const OffersContext = createContext<ReturnType<typeof createOfferStore> | null>(
  null
);

const OfferContextWrapper_ = ({
  children,
  policy,
  tokenName,
}: PropsWithChildren<{ policy: string; tokenName?: string }>) => {
    console.log('>>>>Get Offer', policy, tokenName);

  const { wallet } = useCardanoWallet();
  const offerStoreRef = React.useMemo(
    () => createOfferStore(policy, tokenName),
    [policy, tokenName]
  );

  useEffect(() => {
    if (wallet) {
      offerStoreRef.getState().setWallet(wallet);
    }
  }, [wallet, offerStoreRef]);

  useEffect(() => () => {
    // Cleanup UseEffect
    const { collectionListener, offersListeners } = offerStoreRef.getState();
    collectionListener();
    offersListeners.forEach(l => l());
  }, [offerStoreRef]);

  return (
    <OffersContext.Provider value={offerStoreRef}>
      {children}
    </OffersContext.Provider>
  );
};

export const OfferContextWrapper = React.memo(OfferContextWrapper_);

const useOfferStore = () => {
  const store = useContext(OffersContext);

  if (!store) {
    throw new Error('useOfferStore must be used within a OfferContextWrapper');
  }

  return store;
};

// Store Selectors
const selectRefresh = (store: OffersStore) => store.refresh;
const selectPolicy = (store: OffersStore) => store.policy;
const selectTokenName = (store: OffersStore) => store.tokenName;
const selectWallet = (store: OffersStore) => store.wallet;
const selectSetWallet = (store: OffersStore) => store.setWallet;
const selectSetTokenName = (store: OffersStore) => store.setTokenName;
//const selectAcceptOffer = (store: OffersStore) => store.acceptOffer;
const selectCancelOffer = (store: OffersStore) => store.cancelOffer;
const selectCreateOffer = (store: OffersStore) => store.createOffer;
const selectTxState = (store: OffersStore) => store.txState;
const selectGetCollectionStats = (store: OffersStore) => store.collectionStats;
const selectAllOffers = (store: OffersStore) => {
  // const walletAddresses = store.walletAddresses || [];
  // console.log('walletAddresses', walletAddresses.length);
  const allOffers = store.allOffers;

  return Array.from(allOffers.values()).flatMap((offers) => Array.from(offers.values()));
};

const selectAllCollectionAssetOffersOffers = (store: OffersStore) => {
  // const walletAddresses = store.walletAddresses || [];
  // console.log('walletAddresses', walletAddresses.length);
  return store.allCollectionAssetOffers;
};

export type OfferResponseWithMyOffer = ReturnType<
  typeof selectAllOffers
>;

export const useRefresh = () => useStore(useOfferStore(), selectRefresh);
export const usePolicy = () => useStore(useOfferStore(), selectPolicy);
export const useTokenName = () => useStore(useOfferStore(), selectTokenName);
export const useWallet = () => useStore(useOfferStore(), selectWallet);
export const useSetWallet = () => useStore(useOfferStore(), selectSetWallet);
export const useAllOffers = () => useStore(useOfferStore(), selectAllOffers);
export const useAllCollectionAssetOffers = () =>
  useStore(useOfferStore(), selectAllCollectionAssetOffersOffers);
export const useSetTokenName = () =>
  useStore(useOfferStore(), selectSetTokenName);
//export const useAcceptOffer = () =>
//useStore(useOfferStore(), selectAcceptOffer);
export const useCancelOffer = () =>
  useStore(useOfferStore(), selectCancelOffer);
export const useCreateOffer = () =>
  useStore(useOfferStore(), selectCreateOffer);
export const useTxState = () => useStore(useOfferStore(), selectTxState);
export const useOfferStats = () =>
  useStore(useOfferStore(), selectGetCollectionStats);
