import {
  useAllCollectionAssetOffers,
  useAllOffers,
  useOfferStats,
  useRefresh,
} from '#components/Assets/AssetPage/MarketActionOffers/OffersStore';
import DaisyButton from '#components/Daisy/DaisyButton';
import { Asset, Project } from '#types/index';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { useCallback, useEffect, useState } from 'react';
import { TxDisplay } from './TxDisplay';
import { getAssetFromPolicyAndTokenName } from '#lib/firestore';
import {
  OfferUIStore,
  useOfferUIStore,
} from '#components/Assets/AssetPage/MarketActionOffers/OfferUIStore';
import { useCardanoWallet } from '#lib/wallet/WalletContext';
import cn from 'classnames';
import { AssetImage } from '#components/Assets/AssetPage/AssetImage';
import Link from 'next/link';

export type ProjectOffersProps = {
  project: Project;
};
dayjs.extend(relativeTime);
dayjs.extend(utc);
export const ProjectOffers = ({ project }: ProjectOffersProps) => {
  const [showAssetOffers, setShowAssetOffers] = useState(true);
  const [mode, setMode] = useState('COLLECTION');
  const { wallet } = useCardanoWallet();
  const setWallet = useOfferUIStore(selectSetWallet);
  const offers = useAllOffers();

  useEffect(() => {
    if (!wallet) return;
    setWallet(wallet);
  }, [wallet, setWallet]);

  console.log('Offers', offers);

  const refreshOffers = useRefresh();
  const collectionOffers = offers
    .flatMap((offer) =>
      offer.details
        .filter((detail) => detail.type === 'Collection')
        .map((detail) => ({
          ...detail,
          tx_hash: offer.tx_hash,
          tx_index: offer.index.toString(),
          has_collection_offer: Boolean(offer.details.find((a) => a.type === 'Collection')),
          has_asset_offer: Boolean(offer.details.find((a) => a.type === 'Asset')),
          time: offer.time,
        }))
    )
    .sort((a, b) => b.offer - a.offer);

  const assetOffers = offers
    .flatMap((offer) =>
      offer.details
        .filter((detail) => detail.type === 'Asset')
        .map((detail) => ({
          ...detail,
          tx_hash: offer.tx_hash,
          tx_index: offer.index.toString(),
          has_collection_offer: Boolean(offer.details.find((a) => a.type === 'Collection')),
          has_asset_offer: Boolean(offer.details.find((a) => a.type === 'Asset')),
          time: offer.time,
        }))
    )
    .sort((a, b) => b.offer - a.offer);

  const allOffers = assetOffers
    .concat(collectionOffers)
    .sort((a, b) => b.offer - a.offer);

  // collectionOffers.concat(assetOffers);

  if (
    (!offers || (!!offers && offers.length == 0)) &&
    (!assetOffers || (!!assetOffers && assetOffers.length == 0))
  )
    return (
      <>
        <div className="h-96 flex items-center justify-center">
          <DaisyButton
            icon={<ArrowPathIcon className="w-4 h-4" />}
            classNames="btn-circle btn-sm btn-ghost"
            label=""
            onClick={() => refreshOffers()}
          />
          <p className="font-normal dark:text-gray-400">
            There are no collection offers here yet.{' '}
          </p>
        </div>
      </>
    );

  return (
    <>
      <div className="w-full mt-6 flex justify-center">
        <div className="w-full max-w-full">
          <span className="w-full flex items-center justify-between mb-2">
            <span className="flex items-center gap-3">
              <h1
                className="font-medium text-xl cursor-pointer"
                onClick={() => {
                  setMode('ALL');
                }}
              >
                Offers ({offers.length + assetOffers.length})
              </h1>
              <div className="form-control flex gap-2 -mb-2">
                <label className="cursor-pointer label">
                  <input
                    type="checkbox"
                    checked={showAssetOffers}
                    onChange={(t) => {
                      const { checked } = t.target as unknown as {
                        checked: boolean;
                      };
                      setShowAssetOffers(checked);
                    }}
                    className="checkbox checkbox-secondary checkbox-sm"
                  />
                  <span className="label-text ml-2">Show Asset Offers</span>
                </label>
              </div>
            </span>

            <span className="flex flex-row items-center gap-1">
              <DaisyButton
                icon={<ArrowPathIcon className="w-4 h-4" />}
                classNames=" btn-xs btn-ghost"
                size="btn-sm"
                label="Refresh"
                onClick={() => refreshOffers()}
              />
            </span>
          </span>
          <table className="table w-full mb-12">
            {/* head */}
            <thead>
              <tr>
                <th>Offer</th>
                <th>Asset</th>
                <th>Date</th>
                <th className="w-80">Transaction</th>
              </tr>
            </thead>
            <tbody>
              <>
                {allOffers
                  .filter((o) =>
                    showAssetOffers == true ? true : o.type == 'Collection'
                  )
                  .filter((o) => o.policy === project.policy)
                  .map((offer, index) => (
                    <tr key={index}>
                      <td>
                        <p className="text-xl font-bold px-4 py-2">
                          ₳{(offer.offer / 1_000_000).toLocaleString()}
                        </p>
                      </td>

                      <td className="">
                        {offer.tokenName && offer.policy ? (
                          <AssetOfferAssetInfo
                            tokenName={offer.tokenName}
                            policy={offer.policy}
                          />
                        ) : (
                          <p className="font-bold">Collection Offer</p>
                        )}
                      </td>
                      <td>
                        <div className="flex flex-col font-normal">
                          {dayjs
                            .utc(offer.time * 1_000)
                            .local()
                            .format('DD MMM, YYYY | HH:mm')}{' '}
                          <span className=" text-xs dark:text-gray-400 text-gray-600 font-normal">
                            ({dayjs.utc(offer.time * 1_000).fromNow()})
                          </span>
                        </div>
                      </td>

                      <td>
                        <TxDisplay txHash={offer.tx_hash} />
                      </td>

                      {/* Render additional properties as needed */}
                    </tr>
                  ))}
              </>

              {/* {offers &&
              offers
                .filter((a) => a.has_collection_offer)
                .sort()
                .map((o, idx) => (
                  <OfferRow offer={o} key={`${o.tx_hash}${idx}`} />
                ))} */}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

type AssetOfferProps = {
  tokenName: string;
  policy: string;
};

const AssetOfferAssetInfo = ({ tokenName, policy }: AssetOfferProps) => {
  const [theAsset, setTheAsset] = useState<Asset | undefined>();
  const getAsset = useCallback(async () => {
    const asset = await getAssetFromPolicyAndTokenName(policy, tokenName);
    setTheAsset(asset);
  }, [policy, tokenName]);

  useEffect(() => {
    getAsset();
  }, [getAsset]);

  return (
    <div className="h-16">
      <div className="flex items-center gap-4">
        <div className="w-12 h-12">
          {!!theAsset && <AssetImage asset={theAsset} />}
        </div>
        <div>
          <Link href={`/nft/${theAsset?.id}`}>
            <a>{theAsset?.title}</a>
          </Link>
        </div>
      </div>
    </div>
  );
};

const selectMyOffers = (state: OfferUIStore) => state.myOffers;
const selectSetWallet = (state: OfferUIStore) => state.setWallet;
