import { Fragment, useEffect, useState } from 'react';
import { FC } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Divider } from 'components/Divider/Divider';
import { useFirebaseCurrentUser } from '#lib/hooks/useFirebaseCurrentUser';
import { Asset, Project } from '#types/index';
import { useNotifier } from 'react-headless-notifier';
import DaisyButton from '#components/Daisy/DaisyButton';
import { DaisyTextInput } from '#components/Daisy/DaisyTextInput';
import {
  OfferUIStore,
  useOfferUIStore,
} from '#components/Assets/AssetPage/MarketActionOffers/OfferUIStore';
import { NextImage } from '#components/Assets/NextImage';
import { MiddleEllipsis } from '#components/MiddleEllipsis/MiddleEllipsis';
import { VerifiedCollectionPopover } from '#components/Tooltip/VerifiedCollectionPopover';
import { useCardanoWallet } from '#lib/wallet/WalletContext';
import { DropspotMarketError } from '#lib/plutus/DropspotMarketError';
import cn from 'classnames';
import { TwitterIcon } from '#components/svg/twitterIcon';
import { PolicyTooltipV2 } from '#components/Tooltip/PolicyTooltipV2';
import { TxDisplay } from './TxDisplay';
import { AssetImage } from '#components/Assets/AssetPage/AssetImage';
import { OfferErrorModal } from './OfferErrorModal';
import { logger } from '../../lib/Logger';
import { getProjectByPolicy } from '#lib/firestore';

type ButtonType = 'Button' | 'Icon';
export interface OfferModalProps {
  project: Project | undefined;
  asset?: Asset;
  open: boolean;
  floor?: number;
  offerType: 'COLLECTION' | 'ASSET';
  hideLaunchButton?: boolean;
  offerAdded: () => void;
  onClose?: () => void;
}

export const SingleOfferModal: FC<OfferModalProps> = ({
  project,
  asset,
  open,
  floor,
  offerType,
  hideLaunchButton = false,
  offerAdded,
  onClose,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const [price, setPrice] = useState<number | undefined>(floor);
  const setCollectionOffer = useOfferUIStore(selectAddCollectionToOffer);
  const setAssetOffer = useOfferUIStore(selectAddAssetToOffer);
  const setUser = useOfferUIStore(selectSetUser);
  const createOffer = useOfferUIStore(selectCreateOffer);
  const txState = useOfferUIStore(selectTxState);
  const txInProgress = useOfferUIStore(selectTxInProgress);
  function openModal() {
    setIsOpen(true);
  }
  const { wallet } = useCardanoWallet();

  const { currentUser } = useFirebaseCurrentUser();
  useEffect(() => setIsOpen(open), [open]);

  const isReadyToCreate = () => !!price;
  const { notify } = useNotifier();
  const [displayError, setDisplayError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [offerTx, setOfferTx] = useState<string | undefined>();
  const [assetProject, setAssetProject] = useState<Project | undefined>(
    project
  );
  const assetPolicy = asset?.policy || asset?.policy_id;
  if (!assetProject) {
    getProjectByPolicy(assetPolicy || '').then((p) => {
      setAssetProject(p);
    });
  }

  if (!assetProject) {
    return <span className="loading loading-spinner loading-lg"></span>;
  }

  const showNotification = (type: string, message: string) => {
    notify(
      <div className="toast toast-end min-w-[18rem] z-[60] ztop">
        <div className={`alert ${type}`}>
          <div>
            <span>{message}</span>
          </div>
        </div>
      </div>,
      {
        max: 1,
        position: 'bottomRight',
        duration: 3000,
      }
    );
  };

  const setOfferPrice = (percent: number) => {
    if (!floor) return;
    setPrice(floor * (1 - percent));
  };

  const doOnCloseCleanup = () => {
    setDisplayError(false);
    setIsSuccess(false);
    setIsLoading(false);
    setPrice(floor);
    setOfferTx(undefined);
  };

  const makeOffer = async () => {
    // DO SHIT - get offer amount and expiry.
    if (!project) {
      console.log('project is null');
      return;
    }
    if (!project.policy) {
      console.log('no policy');
      return;
    }
    if (!price || price < 5) {
      console.log('price is out');
      return;
    }
    if (!wallet) return;
    if (!currentUser) return;
    console.log('Passed some tests');
    //set user
    setUser(currentUser);
    if (offerType == 'COLLECTION') {
      setCollectionOffer(project, price * 1_000_000);
    } else {
      if (!!asset) {
        setAssetOffer(asset, price * 1_000_000);
      }
    }

    setIsLoading(true);
    createOffer(wallet, 999999999999999)
      .then((tx) => {
        setIsSuccess(true);
        setIsLoading(false);
        setOfferTx(tx);
      })
      .catch((error) => {
        logger.error({
          message: error.message,
          level: 'error',
          timestamp: Date.now(),
          additional: {
            error: JSON.stringify(error),
          },
        });

        if (error instanceof DropspotMarketError) {
          const e = error;
          setDisplayError(true);
          showNotification(
            e.code == 2 ? 'alert-info' : 'alert-error',
            `${e.message}`
          );
          setErrorMessage(e.message);
        }
        setIsLoading(false);

        // showNotification(
        //   'alert-error',
        //   `${typeof error} There has been an error submitting your offer. Please contact Dropspot support via discord if the problem persists.`
        // );
        //  setIsOpen(false);
      });
  };

  const constructTweet = () => {
    if (!assetProject) {
      console.log('assetProject is null');
      return '';
    }

    const baseUrl = `https://dropspot.io/marketplace/${assetProject.tokenPrefix}`;
    let tweetText = `I've just made a ${assetProject.name} Collection Offer on @dropspot_io for ${price}ADA. LFG 🔥"`;

    if (assetProject.creator.twitterHandle) {
      tweetText = `I've just made a @${assetProject.creator.twitterHandle} Collection Offer on @dropspot_io for ${price}ADA. LFG 🔥. \n\n👇\n${baseUrl}`;
    }

    const encodedTweetText = encodeURIComponent(tweetText);

    // Now you can use the `encodedTweetText` in your URL
    const twitterIntentUrl = `https://twitter.com/intent/tweet?text=${encodedTweetText}`;
    console.log(twitterIntentUrl);

    return twitterIntentUrl;
  };

  return (
    <>
      {hideLaunchButton != true && (
        <div className="">
          <DaisyButton
            onClick={() => {
              setErrorMessage(''), setDisplayError(false);
              openModal();
            }}
            label={offerType == 'ASSET' ? 'Make Offer' : 'Collection Offer'}
            size="btn-sm"
            colorName="btn-secondary"
            classNames="text-gray-800 dark:text-white"
          />
        </div>
      )}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          open={isOpen}
          className="fixed inset-0 z-[55] overflow-y-auto transition-height duration-150 transform"
          onClose={() => {
            doOnCloseCleanup();
            onClose && onClose();
            setIsOpen(false);
          }}
        >
          <div className="min-h-screen px-4 text-center transition-height duration-150 transform">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 backdrop-filter backdrop-blur-xl backdrop-brightness-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block dark:bg-base-300 w-full max-w-lg p-9 my-12 overflow-hidden text-left align-middle  bg-white shadow-xl rounded-2xl transition-height duration-150 transform">
                <div className="flex flex-row justify-between">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-bold leading-6 text-gray-800 dark:text-white flex items-center gap-3"
                  >
                    {offerType == 'ASSET' ? 'Asset' : 'Collection'} Offer
                  </Dialog.Title>
                </div>
                <div className="mt-4">
                  <Divider />
                </div>

                <div
                  className={cn(
                    'duration-200 transition-all',
                    {
                      'h-[16rem] ': displayError == true,
                    },
                    {
                      'h-[30rem]': isSuccess == true,
                    },
                    {
                      'h-[28rem]': !displayError && !isSuccess && !floor,
                    },
                    {
                      'h-[34rem]': !displayError && !isSuccess && !!floor,
                    }
                  )}
                >
                  {!!displayError && (
                    <>
                      <div className="w-full flex justify-between h-full flex-col items-center">
                        <span className="text-3xl font-bold mt-8 mb-8">
                          Transaction Cancelled
                        </span>
                        <div className="mb-8">{errorMessage}</div>
                        <DaisyButton
                          label="OK"
                          classNames="w-full"
                          onClick={() => {
                            doOnCloseCleanup();
                            onClose && onClose();
                            setIsOpen(false);
                          }}
                        />
                      </div>
                    </>
                  )}
                  {!!isSuccess && (
                    <>
                      <div className="w-full flex justify-center flex-col items-center">
                        <span className="text-3xl font-bold mt-6 mb-4">
                          Offer Made 🎉
                        </span>
                        <div className="mb-8 text-center font-normal">
                          Congratulations. Your offer has been submitted, and is
                          being confirmed onchain.
                        </div>
                        <div className="p-4 rounded-xl h-16 flex flex-row items-center justify-between bg-base-100 w-full mb-8">
                          <span className="text-gray-600 dark:text-gray-400">
                            Transaction:
                          </span>
                          <TxDisplay txHash={offerTx || ''} />
                        </div>
                        <div className="mb-8 text-center font-normal text-sm dark:text-gray-400">
                          Please allow up to 30s for this offer to be confirmed
                          and available via 3rd party explorer sites.
                        </div>
                        <div className="flex flex-col gap-4 w-full mt-6">
                          <a
                            className="btn btn-secondary"
                            target="_blank"
                            href={constructTweet()}
                            rel="noreferrer"
                          >
                            {' '}
                            <span className="flex gap-2 items-center">
                              <TwitterIcon />
                              <span>Promote</span>
                            </span>
                          </a>
                          <DaisyButton
                            label="OK"
                            classNames="w-full"
                            onClick={() => {
                              doOnCloseCleanup();
                              onClose && onClose();
                              setIsOpen(false);
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {!displayError && !isSuccess && (
                    <div className="w-full flex flex-col gap-4 mb-6 justify-center mt-4">
                      {!!asset && (
                        <>
                          <div className="flex items-end gap-4">
                            <div className="w-24 h-24 relative rounded-xl flex-none">
                              <AssetImage asset={asset} />
                            </div>
                            <div className="w-full flex flex-col">
                              <span className="font-normal text-lg w-48">
                                {assetProject.policy && (
                                  <MiddleEllipsis text={asset.id} />
                                )}
                              </span>
                              <p className="font-bold text-xl">{asset.title}</p>
                            </div>
                          </div>
                        </>
                      )}
                      {!asset && (
                        <>
                          <div className="flex items-end gap-4">
                            <div className="w-24 h-24 relative rounded-xl flex-none">
                              <NextImage
                                src={assetProject.heroImage}
                                layout={'fill'}
                                width={100}
                                priority={false}
                                className="object-cover rounded-xl"
                                b64={assetProject.heroImageB64}
                              />
                            </div>
                            <div className="w-full flex flex-col">
                              <VerifiedCollectionPopover
                                project={assetProject}
                              />
                              <span className="font-normal text-lg w-48">
                                {assetProject.policy && (
                                  <MiddleEllipsis text={assetProject.policy} />
                                )}
                              </span>
                              <p className="font-bold text-xl">
                                {assetProject.name}
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                      {/* <div className="flex items-end gap-4">
                    <div className="w-24 h-24 relative rounded-xl flex-none">
                      <NextImage
                        src={asset.thumbnailUrl}
                        layout={'fill'}
                        width={100}
                        priority={false}
                        className="object-cover rounded-xl"
                        b64={asset.thumbnailB64}
                      />
                    </div>
                    <div className="w-full flex flex-col">
                      <p className="font-normal text-lg">
                        {asset.algoliaProjectFacet &&
                          JSON.parse(asset.algoliaProjectFacet).title}
                      </p>
                      <p className="font-bold text-lg">{asset.title}</p>
                    </div>
                  </div> */}
                      <div className="flex-1">
                        <DaisyTextInput
                          id="policy"
                          label=""
                          colorName="input-secondary"
                          placeholder="Offer a price"
                          size="input-lg"
                          labelPosition="inside"
                          value={price && price.toFixed(0)}
                          onChange={(price) => setPrice(Number.parseInt(price))}
                        />
                      </div>

                      {!!floor && (
                        <>
                          <div>
                            <span className="font-normal dark:text-gray-400 text-gray-600 text-sm">
                              Set price at a % below the current floor of:{' '}
                              {floor}
                            </span>
                          </div>
                          <div className="flex flex-row gap-1">
                            <DaisyButton
                              label="1%"
                              classNames="flex-1"
                              onClick={() => setOfferPrice(0.01)}
                            />
                            <DaisyButton
                              label="2%"
                              classNames="flex-1"
                              onClick={() => setOfferPrice(0.02)}
                            />
                            <DaisyButton
                              label="5%"
                              classNames="flex-1"
                              onClick={() => setOfferPrice(0.05)}
                            />
                            <DaisyButton
                              label="10%"
                              classNames="flex-1"
                              onClick={() => setOfferPrice(0.1)}
                            />
                            <DaisyButton
                              label="15%"
                              classNames="flex-1"
                              onClick={() => setOfferPrice(0.15)}
                            />
                            <DaisyButton
                              label="25%"
                              classNames="flex-1"
                              onClick={() => setOfferPrice(0.25)}
                            />
                            <DaisyButton
                              classNames="flex-1"
                              label="50%"
                              onClick={() => setOfferPrice(0.5)}
                            />
                          </div>
                        </>
                      )}

                      <div className="bg-base-100 p-5 rounded-lg">
                        <h2 className="text-secondary">
                          {!!asset ? 'Asset Offer' : 'Collection Offer'}
                        </h2>
                        <p className="font-normal">
                          This offer is for any asset minted with the policy:
                        </p>
                        <PolicyTooltipV2 policy={assetProject.policy} />
                      </div>
                      <div className="flex-1 flex flex-col gap-2">
                        <DaisyButton
                          label={
                            !txInProgress ? 'Make Offer' : (txState as string)
                          }
                          classNames="flex-1"
                          colorName="btn-secondary"
                          working={!!txInProgress || !!isLoading}
                          workingLabel={txState || 'Finalizing...'}
                          isDisabled={
                            !isReadyToCreate() || !!txInProgress || !!isLoading
                          }
                          onClick={() => {
                            console.log('Make Offer');
                            makeOffer();
                          }}
                        />
                        <DaisyButton
                          label="Cancel"
                          colorName="btn-ghost"
                          size="btn-sm"
                          classNames="flex-1"
                          onClick={() => {
                            doOnCloseCleanup();
                            onClose && onClose();
                            setIsOpen(false);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

const selectAddCollectionToOffer = (state: OfferUIStore) =>
  state.setCollectionOffer;
const selectAddAssetToOffer = (state: OfferUIStore) => state.setAssetOffer;
const selectSetUser = (state: OfferUIStore) => state.setCurrentUser;
const selectCreateOffer = (state: OfferUIStore) => state.createOffer;
const selectTxState = (state: OfferUIStore) => state.txState;
const selectTxInProgress = (state: OfferUIStore) => state.txInProgress;
