import getAbsoluteURL from '#lib/getAbsoluteUrl';
import { getAddress } from '#lib/plutus/DropspotMarketContract';
import { CardanoWalletExtended } from '#lib/wallet/WalletContext';
import { Address, bytesToHex } from 'helios16';
import { flatten } from '#lib/utils/stringUtils';
import { getUtxos as getUtxosV2 } from '#lib/plutus/CoinSelection16';
import { InferQueryOutput } from '#lib/trpc';
import { logger } from '#lib/Logger';
import { DropspotMarketError } from './DropspotMarketError';

type AssetUtxoType = InferQueryOutput<'asset-utxo-2'>;

async function handleFetch(body: unknown) {
  const url = getAbsoluteURL('/api/txn/market');

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    const err = await response.text();
    logger.error({
      level: 'error',
      message: 'Failed to build transaction',
      additional: err,
    });
    throw new DropspotMarketError({
      code: 2,
      info: err,
      type: 'SEND',
    });
  }

  return response.text();
}

export async function buildRelistTransaction(
  wallet: CardanoWalletExtended,
  utxo: AssetUtxoType[number] & { type: 'TXN' },
  price: string,
  cTxHash?: string,
  cTxIndex?: number
) {
  const [selected] = await getUtxosV2(wallet, BigInt(5_000_000));

  return handleFetch({
    action: 'relist',
    walletAddress: Address.fromHex(await getAddress(wallet)).toBech32(),
    price: price,
    utxos: selected.map((u) => bytesToHex(u.toFullCbor())),
    datum: utxo.hDatum,
    datumHash: utxo.datum.hash,
    purchaseUtxo: utxo.utxoCbor,
    ownerAddress: flatten(utxo.asset_owner),
    superCubeEnabled: Boolean(utxo.supercube_enabled),
    contractUTxO:
      cTxHash !== undefined && cTxIndex !== undefined
        ? {
            txHash: cTxHash,
            index: cTxIndex,
          }
        : undefined,
  });
}

export async function buildListingTransaction(
  wallet: CardanoWalletExtended,
  assets: {
    tokenName: string;
    policy: string;
    quantity: number;
    price: string;
  }[]
) {
  const [selected] = await getUtxosV2(wallet, BigInt(5_000_000));

  return handleFetch({
    action: 'list',
    walletAddress: Address.fromHex(await getAddress(wallet)).toBech32(),
    policy: assets[0].policy,
    assetName: assets[0].tokenName,
    quantity: assets[0].quantity,
    price: assets[0].price,
    utxos: selected.map((u) => bytesToHex(u.toFullCbor())),
  });
}

export async function buildBuyOrCancelTransaction(
  action: 'buy' | 'cancel',
  wallet: CardanoWalletExtended,
  utxo: AssetUtxoType[number] & { type: 'TXN' },
  cTxHash?: string,
  cTxIndex?: number
) {
  const walletAddress = await getAddress(wallet);

  const llNeeded =
    action === 'buy'
      ? utxo.price
      : utxo.contract_type === 'Sale-Mint'
      ? 7_000_000
      : 2_000_000;

  const [selected] = await getUtxosV2(wallet, BigInt(llNeeded));

  const contractUTxO =
    cTxHash !== undefined && cTxIndex !== undefined
      ? {
          txHash: cTxHash,
          index: cTxIndex,
        }
      : undefined;

  if (action === 'buy') {
    return handleFetch({
      action,
      assets: [
        {
          purchaseUtxo: utxo.utxoCbor,
          datum: utxo.hDatum,
          datumHash: utxo.datum.hash,
        },
      ],
      superCubeEnabled: true,
      ownerAddress: flatten(utxo.asset_owner),
      utxos: selected.map((u) => bytesToHex(u.toFullCbor())),
      walletAddress: Address.fromHex(walletAddress).toBech32(),
      contractUTxO,
    });
  }

  return handleFetch({
    action,
    superCubeEnabled: true,
    ownerAddress: flatten(utxo.asset_owner),
    purchaseUtxo: utxo.utxoCbor,
    datum: utxo.hDatum,
    datumHash: utxo.datum.hash,
    utxos: selected.map((u) => bytesToHex(u.toFullCbor())),
    walletAddress: Address.fromHex(walletAddress).toBech32(),
    contractUTxO,
  });
}
