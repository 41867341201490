import { createStore, useStore } from 'zustand';
import { Asset, Project } from '#types/index';
import { CardanoWalletExtended } from '#lib/wallet/WalletContext';
import { InferQueryOutput, trpcClient } from '#lib/trpc';
import React, { PropsWithChildren, useContext } from 'react';
import { utils } from '@dropspot-io/contract-api';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import {
  APIError,
  DropspotMarketError,
  InternalErrorCode,
} from '#lib/plutus/DropspotMarketError';
import {
  BUILD_ACTION,
  getAddress,
  getNetworkParams,
  submitTx,
  updateAssetListing,
} from '#lib/plutus/DropspotMarketContract';

import {
  Address,
  Assets,
  CborData,
  ListData,
  MintingPolicyHash,
  hexToBytes,
  StakeAddress,
  Value,
  UTxO,
  bytesToHex,
  textToBytes,
} from '@hyperionbt/helios';
import { errorIsAPIError } from '../ChainInteraction/utils';
import constants from '#lib/constants';
import { devtools } from 'zustand/middleware';
import {
  deleteExternalListingSyncInsert,
  getMaestroTransaction,
} from '../../../../lib/firestore';

import { coinSelection } from '#lib/plutus/CoinSelection';
import getAbsoluteURL from '#lib/getAbsoluteUrl';
import { signAndSubmit } from '../../../../lib/transaction/utils';
import { flatten } from '#lib/utils/stringUtils';
import { getUtxos } from '#lib/plutus/DropspotMarketContract';
import { Tx, TxWitnesses } from 'helios16';
import {
  buildBuyOrCancelTransaction,
  buildListingTransaction,
  buildRelistTransaction,
} from '#lib/plutus/MarketTransctions';

type AssetUtxoType = InferQueryOutput<'asset-utxo-2'>;
type MarketStore = {
  policy?: string;
  tokenName?: string;
  assetStandard: 'CIP25' | 'CIP68';
  assetId: string;
  assetUrl: Asset['assetUrl'];
  thumbnailB64: Asset['thumbnailB64'];
  title: Asset['title'];
  royaltyItems?: Project['royaltyItems'];
  utxos: AssetUtxoType;
  loading: boolean;
  wallet?: CardanoWalletExtended;
  walletPKH?: string;
  currentOwner?: boolean;
  reload: () => Promise<void>;
  setWallet: (wallet: CardanoWalletExtended) => void;

  performTransaction: (
    type: 'buy' | 'cancel',
    utxo: AssetUtxoType[number]
  ) => Promise<void>;
  performListing: (
    listing:
      | {
          type: 'list';
        }
      | {
          type: 'relist';
          utxo: AssetUtxoType[number];
        }
      | {
          type: 'delistToDs';
          utxo: AssetUtxoType[number];
        }
  ) => Promise<void>;
  currentListing?: Parameters<MarketStore['performListing']>[0];
  doListing: (
    listing: Parameters<MarketStore['performListing']>[0],
    price: number
  ) => Promise<void>;
  error?: DropspotMarketError;
  /**
   * The status of the action modal
   * - OFF: We do not show a Modal
   * - BUILDING: This is used for List and Relist where we show the modal but there is no TXN running yet
   * - WAITING: We have a Transaction being processed
   */
  actionModalStatus: 'OFF' | 'BUILDING' | 'WAITING';
  txState?: BUILD_ACTION;
  clearError: () => void;
  closeActionModal: () => void;
  action?:
    | 'REPRICE'
    | 'CREATE LISTING'
    | 'BUY'
    | 'CLAIM'
    | 'DELIST'
    | 'DELISTODS';
  awaitingSignature: boolean;
  txInProgress: boolean;
  waitForTransaction: (txHash: string) => Promise<void>;
  refreshing: boolean;
  delistToDs: (
    listing: AssetUtxoType[number],
    price: number,
    wallet: CardanoWalletExtended,
    addy: string | undefined
  ) => Promise<void>;
};

export const createMarketStore = (asset: Asset, project?: Project) => {
  console.log('>>>>createMarketStore', asset);
  const name =
    (asset.assetStandard == 'CIP68'
      ? asset.tokenNameEncoded
      : asset.tokenName) || '';
  const policy = asset.policy || '';

  const store = createStore<MarketStore, [['zustand/devtools', MarketStore]]>(
    devtools<MarketStore>(
      (set, get) => {
        return {
          refreshing: false,
          policy: asset.policy,
          tokenName:
            asset.assetStandard == 'CIP68'
              ? asset.tokenNameEncoded
              : asset.tokenName,
          assetStandard: asset.assetStandard || 'CIP25',
          assetId: asset.id,
          assetUrl: asset.assetUrl,
          txInProgress: !!asset.txHash,
          thumbnailB64: asset.thumbnailB64,
          title: asset.title,
          royaltyItems: project?.royaltyItems,
          utxos: [],
          actionModalStatus: 'OFF',
          loading: true,
          awaitingSignature: false,
          setWallet: (wallet) => {
            if (store.getState().wallet === wallet) return;
            store.setState((s) => ({ ...s, wallet }));

            getAddress(wallet).then((address) => {
              store.setState({
                walletPKH: Address.fromHex(address).pubKeyHash.hex,
              });
            });

            wallet.getBalance().then((balance) => {
              // console.log('Wallet Balance', balance);

              let isAssetOwner = false;

              if (asset.policy && asset.tokenName) {
                if (CborData.isList(hexToBytes(balance))) {
                  const l = ListData.fromCbor(hexToBytes(balance));

                  const a = Assets.fromCbor(l.list[1].toCbor());

                  isAssetOwner = a.has(
                    MintingPolicyHash.fromHex(asset.policy),
                    Array.from(Buffer.from(asset.tokenName, 'utf8'))
                  );
                }
              }

              store.setState((s) => ({ ...s, currentOwner: isAssetOwner }));
            });
          },
          reload: async () => {
            if (get().refreshing) return;
            console.log('>>>>>>> Reload Called');
            store.setState((s) => ({ ...s, loading: true, refreshing: true }));

            const utxos = await trpcClient.query('asset-utxo-2', {
              name,
              policy,
              standard: asset.assetStandard,
            });
            store.setState((s) => ({
              ...s,
              utxos,
              loading: false,
              refreshing: false,
            }));
          },
          waitForTransaction: async (txHash) => {
            set({ txInProgress: true });
            getMaestroTransaction(txHash, (tx) => {
              console.log('Tx State from Maestro', tx);
              if (!tx) return;
              if (tx.state === 'Onchain') {
                trpcClient
                  .query('asset-utxo-2', {
                    name,
                    policy,
                    standard: asset.assetStandard,
                  })
                  .then((utxos) => set({ utxos, txInProgress: false }));
              }
            });
          },
          performTransaction: async (type, utxo) => {
            const wallet = store.getState().wallet;
            if (!wallet) return;
            console.log('performTransaction', type, utxo);

            store.setState((s) => ({
              ...s,
              actionModalStatus: 'WAITING',
              awaitingSignature: false,
              action: type === 'buy' ? 'BUY' : 'DELIST',
              error: undefined,
              txState: 'Building',
            }));

            try {
              const txId = await processTransaction(
                asset.id,
                type,
                utxo,
                wallet,
                (action) => {
                  store.setState((s) => ({ ...s, txState: action }));
                },
                utxo.c_avail ? utxo.c_tx_hash : undefined,
                utxo.c_avail ? utxo.c_tx_index : undefined
              );
              get()
                .waitForTransaction(txId)
                .finally(() => {
                  store.setState({
                    actionModalStatus: 'OFF',
                  });
                });
              const price = Number.parseInt(utxo.price || '0');
              const assetParams = {
                transaction_id: txId,
                currency: 'AUD',
                value: price,
                itemId: asset.id,
                policy: asset.policy,
              };
              if (type == 'buy') {
                firebase
                  .analytics()
                  .logEvent(firebase.analytics.EventName.PURCHASE, assetParams);
              } else {
                firebase.analytics().logEvent('delist', assetParams);
              }

              if (utxo.asset_owner) {
                const paymentAddress = Address.fromHex(
                  flatten(utxo.asset_owner)
                );
                const stakingHash = paymentAddress.stakingHash;

                const stakeAddress = StakeAddress.fromStakeKeyHash(
                  constants.TESTNET,
                  stakingHash
                );
                //eventually this will be the responsibility of tx listener...
                await updateAssetListing(
                  asset.id,
                  Number.isNaN(price) ? 0 : price,
                  txId,
                  'UNLISTED',
                  type === 'buy' ? 'BUY' : 'CANCEL',
                  stakeAddress.toBech32()
                );
              } else {
                await updateAssetListing(
                  asset.id,
                  Number.isNaN(price) ? 0 : price,
                  txId,
                  'UNLISTED',
                  type === 'buy' ? 'BUY' : 'CANCEL'
                );
              }
            } catch (err) {
              if (errorIsAPIError(err)) {
                const e: DropspotMarketError = err;
                store.setState((s) => ({ ...s, error: e }));
              } else {
                console.error(err);

                store.setState((s) => ({
                  ...s,
                  error: new DropspotMarketError({
                    code: InternalErrorCode.UNEXPECTED,
                    info: (err as Error).message,
                    type: 'INTERNAL',
                  }),
                }));
              }
            }
          },
          performListing: async (listing) => {
            store.setState((s) => ({
              ...s,
              currentListing: listing,
              actionModalStatus: 'BUILDING',
              awaitingSignature: false,
              action: listing.type === 'list' ? 'CREATE LISTING' : 'REPRICE',
              error: undefined,
              txState: undefined,
            }));
          },
          doListing: async (listing, price) => {
            const wallet = store.getState().wallet;
            if (!wallet) return;

            const { policy, assetStandard, tokenName, assetId } =
              store.getState();
            if (!policy || !tokenName) return;

            store.setState((s) => ({
              ...s,
              actionModalStatus: 'WAITING',
              awaitingSignature: true,
              txState: 'Building',
            }));

            try {
              const txId = await processListing(
                wallet,
                listing,
                policy,
                tokenName,
                assetStandard,
                assetId,
                price,
                (action) => {
                  store.setState((s) => ({ ...s, txState: action }));
                }
              );

              get()
                .waitForTransaction(txId)
                .finally(() => {
                  store.setState({
                    actionModalStatus: 'OFF',
                  });
                });
              const assetParams = {
                transaction_id: txId,
                currency: 'AUD',
                value: price,
                itemId: asset.id,
                policy: asset.policy,
              };
              if (listing.type == 'list') {
                firebase.analytics().logEvent('list', assetParams);
              } else {
                firebase.analytics().logEvent('relist', assetParams);
              }
              await updateAssetListing(
                asset.id,
                price,
                txId,
                'UNLISTED',
                listing.type === 'list' ? 'LIST' : 'RELIST'
              );
            } catch (err) {
              if (errorIsAPIError(err)) {
                const e: DropspotMarketError = err;
                store.setState((s) => ({ ...s, error: e }));
              } else {
                console.error(err);

                store.setState((s) => ({
                  ...s,
                  error: new DropspotMarketError({
                    code: InternalErrorCode.UNEXPECTED,
                    info: (err as Error).message,
                    type: 'INTERNAL',
                  }),
                }));
              }
            }
          },
          clearError: () => {
            store.setState((s) => ({ ...s, error: undefined }));
          },
          closeActionModal: () => {
            store.setState((s) => ({ ...s, actionModalStatus: 'OFF' }));
          },
          delistToDs: async (listing, price, wallet, addy) => {
            try {
              const { tokenName, policy, assetStandard } = get();
              if (!tokenName || !policy)
                throw new Error('No tokenName or policy');
              store.setState((s) => ({
                ...s,
                actionModalStatus: 'WAITING',
                awaitingSignature: true,
                txState: 'Building',
              }));
              if (listing?.type !== 'JPG_TXN') {
                throw new Error('Incorrect Transaction Type for method');
              }
              const [utxos] = await getUtxos(wallet, BigInt(5000000));
              if (!utxos) throw new Error('No Utxos');
              const utxo = listing;
              if (!utxo?.asset_owner) throw new Error('No Asset Owner');

              const maestroUtxo = await trpcClient.query('maestro-utxo', {
                txHash: utxo.tx_hash,
                index: utxo.tx_index.toString(),
              });

              const assets = new Assets();

              maestroUtxo.assets
                .filter((a) => a.unit !== 'lovelace')
                .map((asset) => ({
                  amount: asset.amount,
                  name: Buffer.from(asset.unit.substring(56), 'hex').toString(
                    'hex'
                  ),
                  policy: asset.unit.substring(0, 56),
                }))
                .forEach((asset) => {
                  const mph = MintingPolicyHash.fromHex(asset.policy);
                  const token = hexToBytes(asset.name);
                  console.log('Asset', bytesToHex(token), asset.name);
                  assets.addComponent(mph, token, BigInt(asset.amount));
                });

              const value = new Value(
                maestroUtxo.assets.find((a) => a.unit === 'lovelace')?.amount ||
                  0,
                assets
              );

              if (!maestroUtxo.datum?.bytes) {
                throw new Error('No Datum');
              }

              const jpgUTxO = {
                txHash: maestroUtxo.tx_hash,
                index: maestroUtxo.index,
                address: maestroUtxo.address,
                value: value.toCborHex(),
                datum: maestroUtxo.datum.bytes,
                datumType: maestroUtxo.datum.type,
              };

              const walletAddress = await getAddress(wallet);
              const url = getAbsoluteURL('/api/jpg/relist');
              const txRequest = await fetch(url, {
                method: 'POST',
                body: JSON.stringify({
                  assets: [
                    {
                      jpgUTxO: jpgUTxO,
                      datum: utxo.datum.bytes,
                      listingPrice: price,
                      policy: policy,
                      token:
                        assetStandard === 'CIP68'
                          ? tokenName
                          : bytesToHex(textToBytes(tokenName)),
                    },
                  ],
                  walletUTxOs: utxos.map((utxo) => ({
                    txHash: utxo.txId.hex,
                    index: utxo.utxoIdx,
                    address: utxo.origOutput.address.toBech32(),
                    value: utxo.origOutput.value.toCborHex(),
                    datum: utxo.origOutput.datum?.toCborHex(),
                    datumType: utxo.origOutput.datum?.isInline()
                      ? 'inline'
                      : 'hash',
                  })),
                  walletAddress: walletAddress,
                }),
                headers: {
                  'Content-Type': 'application/json',
                },
              });

              if (!txRequest.ok) {
                throw new Error('Tx Build Error');
              }

              const txCbor = await txRequest.text();
              store.setState((s) => ({
                ...s,
                actionModalStatus: 'WAITING',
                awaitingSignature: true,
                txState: 'Signing',
              }));
              const txId = await signAndSubmit(wallet, txCbor, (action) => {
                store.setState((s) => ({ ...s, txState: action }));
              });
              get()
                .waitForTransaction(txId)
                .finally(() => {
                  store.setState({
                    actionModalStatus: 'OFF',
                  });
                });
              await updateAssetListing(
                asset.id,
                price,
                txId,
                'LISTED',
                'RELIST',
                undefined,
                asset
              );
              console.log('addy', addy);
              deleteExternalListingSyncInsert(asset.id, addy || '');
            } catch (err) {
              if (errorIsAPIError(err)) {
                const e: DropspotMarketError = err;
                store.setState((s) => ({ ...s, error: e }));
              } else {
                console.error(err);

                store.setState((s) => ({
                  ...s,
                  error: new DropspotMarketError({
                    code: InternalErrorCode.UNEXPECTED,
                    info: (err as Error).message,
                    type: 'INTERNAL',
                  }),
                }));
              }
            }
          },
        };
      },
      {
        name: `MarketStore - ${asset.id}`,
        trace: true,
      }
    )
  );

  store.getState().reload();

  if (asset.txHash) {
    store.getState().waitForTransaction(asset.txHash);
  }

  return store;
};

const AssetPageMarketContext = React.createContext<ReturnType<
  typeof createMarketStore
> | null>(null);

export type WrapperRef = { waitForTransaction: (txHash: string) => void };

type AssetPageMarketContextWrapperProps = {
  asset: Asset;
  project?: Project;
  wrapperRef: React.Ref<WrapperRef>;
};

const AssetPageMarketContextWrapper_ = ({
  asset,
  project,
  children,
  wrapperRef,
}: PropsWithChildren<AssetPageMarketContextWrapperProps>) => {
  console.log('>>>>createMarketStore', 'Rerender');
  const marketStoreRef = React.useMemo(
    () => createMarketStore(asset, project),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      asset.policy,
      asset.tokenName,
      asset.id,
      asset.assetUrl,
      asset.thumbnailB64,
      asset.title,
      asset.txHash,
      project?.royaltyItems,
    ]
  );

  React.useImperativeHandle(wrapperRef, () => ({
    waitForTransaction: (txHash) => {
      marketStoreRef.getState().waitForTransaction(txHash);
    },
  }));

  return (
    <AssetPageMarketContext.Provider value={marketStoreRef}>
      {children}
    </AssetPageMarketContext.Provider>
  );
};

async function processJPGTransaction(
  utxo: AssetUtxoType[number],
  wallet: CardanoWalletExtended,
  listener: (action: BUILD_ACTION) => void
) {
  if (utxo.type !== 'JPG_TXN') {
    throw new Error('Incorrect Transaction Type for method');
  }

  // try {

  let url = getAbsoluteURL('/api/jpg/get-required-value');
  const valueNeededResponse = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      datum: utxo.datum.bytes,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!valueNeededResponse.ok) {
    throw new Error('Failed to calculate value needed');
  }

  const valueCbor = await valueNeededResponse.text();

  console.log('data', valueCbor);
  const requiredValue = Value.fromCbor(hexToBytes(valueCbor));
  const utxos = await wallet.getUtxos();
  if (!utxos) throw new Error('No Utxos');
  const allUtxos = utxos.map((utxo) => UTxO.fromCbor(hexToBytes(utxo)) as UTxO);

  const networkParams = await getNetworkParams();

  const [selectedUtxos, otherUtxos] = coinSelection(
    allUtxos,
    requiredValue,
    networkParams
  );

  const { ensureCollateralUTxO } = utils;
  const collateralUtxos = await ensureCollateralUTxO({
    selectedUtxos,
    otherUtxos,
    wallet,
  });

  const walletAddress = await getAddress(wallet);

  const maestroUtxo = await trpcClient.query('maestro-utxo', {
    txHash: utxo.tx_hash,
    index: utxo.tx_index.toString(),
  });

  const assets = new Assets();

  maestroUtxo.assets
    .filter((a) => a.unit !== 'lovelace')
    .map((asset) => ({
      amount: asset.amount,
      name: Buffer.from(asset.unit.substring(56), 'hex').toString('hex'),
      policy: asset.unit.substring(0, 56),
    }))
    .forEach((asset) => {
      const mph = MintingPolicyHash.fromHex(asset.policy);
      const token = hexToBytes(asset.name);
      console.log('Asset', bytesToHex(token), asset.name);
      assets.addComponent(mph, token, BigInt(asset.amount));
    });

  const value = new Value(
    maestroUtxo.assets.find((a) => a.unit === 'lovelace')?.amount || 0,
    assets
  );

  if (!maestroUtxo.datum?.bytes) {
    throw new Error('No Datum');
  }

  const jpgUTxO = {
    txHash: maestroUtxo.tx_hash,
    index: maestroUtxo.index,
    address: maestroUtxo.address,
    value: value.toCborHex(),
    datum: maestroUtxo.datum.bytes,
    datumType: maestroUtxo.datum.type,
  };

  url = getAbsoluteURL('/api/jpg/build-tx');

  const txRequest = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      action: 'purchase',
      network: constants.TESTNET ? 'preprod' : 'mainnet',
      jpgUTxO,
      datum: utxo.datum.bytes,
      walletAddress: walletAddress,
      walletUTxOs: [
        ...collateralUtxos.selectedUtxos.map((utxo) => ({
          txHash: utxo.txId.hex,
          index: utxo.utxoIdx,
          address: utxo.origOutput.address.toBech32(),
          value: utxo.origOutput.value.toCborHex(),
          datum: utxo.origOutput.datum?.toCborHex(),
          datumType: utxo.origOutput.datum?.isInline() ? 'inline' : 'hash',
        })),
        ...collateralUtxos.otherUtxos.map((utxo) => ({
          txHash: utxo.txId.hex,
          index: utxo.utxoIdx,
          address: utxo.origOutput.address.toBech32(),
          value: utxo.origOutput.value.toCborHex(),
          datum: utxo.origOutput.datum?.toCborHex(),
          datumType: utxo.origOutput.datum?.isInline() ? 'inline' : 'hash',
        })),
      ],
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!txRequest.ok) {
    throw new Error('Tx Build Error');
  }

  const txCbor = await txRequest.text();
  listener('Signing');

  return await signAndSubmit(wallet, txCbor, listener);
}

async function processTransaction(
  assetId: string,
  txType: 'buy' | 'cancel',
  utxo: AssetUtxoType[number],
  wallet: CardanoWalletExtended,
  listener: (action: BUILD_ACTION) => void,
  txInputHash?: string,
  txInputIndex?: number
): Promise<string> {
  if (utxo.type === 'JPG_TXN') {
    const txId = await processJPGTransaction(utxo, wallet, listener);
    return txId;
  }

  if (utxo.type !== 'TXN') throw new Error('Invalid UTxO type');

  listener('Building');
  const tx = await buildBuyOrCancelTransaction(
    txType,
    wallet,
    utxo,
    txInputHash,
    txInputIndex
  );
  return processTx(wallet, tx, [assetId], listener);
}

async function processTx(
  wallet: CardanoWalletExtended,
  tx: string,
  assetId: string[],
  listener: (v: BUILD_ACTION) => void
): Promise<string> {
  const hTx = Tx.fromCbor(hexToBytes(tx));
  // Sign the Transaction
  try {
    listener('Signing');
    const signed = await wallet.signTx(tx, true);
    const witnesses = TxWitnesses.fromCbor(hexToBytes(signed));
    hTx.addSignatures(witnesses.signatures);
  } catch (err) {
    throw new DropspotMarketError({
      code: (err as APIError).code,
      info: (err as APIError).info,
      type: 'SIGN',
    });
  }

  listener('Submitting');
  return submitTx(hTx.toCborHex(), assetId);
}

async function processListing(
  wallet: CardanoWalletExtended,
  listing: Parameters<MarketStore['performListing']>[0],
  policy: string,
  tokenName: string,
  assetStandard: 'CIP68' | 'CIP25',
  assetId: string,
  price: number,
  listener: (action: BUILD_ACTION) => void
): Promise<string> {
  if (!policy || !tokenName)
    throw new Error('Invalid Asset State - has it been minted?');

  if (listing.type === 'delistToDs')
    throw new Error('This method is not for JPG Txns');

  let tx = '';
  if (listing.type === 'list') {
    tx = await buildListingTransaction(wallet, [
      {
        price: price.toString(10),
        policy,
        tokenName:
          assetStandard === 'CIP68'
            ? tokenName
            : bytesToHex(textToBytes(tokenName)),
        quantity: 1,
      },
    ]);
  } else {
    const listingUtxo = listing.utxo;

    if (listingUtxo.type !== 'TXN') throw new Error('Bad UTxO type');

    tx = await buildRelistTransaction(
      wallet,
      listingUtxo,
      price.toString(10),
      listingUtxo.c_tx_hash,
      listingUtxo.c_tx_index
    );
  }

  return processTx(wallet, tx, [assetId], listener);
}

/** Hooks */

const useMarketStore = () => {
  const store = useContext(AssetPageMarketContext);

  if (!store) {
    throw new Error('useMarketStore must be used within a MarketStoreProvider');
  }

  return store;
};

//Store Selectors

type UtxoSelectorType = AssetUtxoType[number] & { isOwner?: boolean };
//   ^?

const selectRoyaltyItems = (store: MarketStore) => store.royaltyItems;
const selectCurrentListing = (store: MarketStore) => store.currentListing;
const selectUtxos = (store: MarketStore): Array<UtxoSelectorType> => {
  const pkh = store.walletPKH || '';

  return store.utxos.map((utxo) => {
    if (utxo.type === 'TXN') {
      let isOwner = false;
      if (utxo.asset_owner) {
        isOwner =
          Address.fromHex(utxo.asset_owner.join('')).pubKeyHash.hex === pkh;
      }

      return {
        ...utxo,
        isOwner,
      };
    }

    try {
      return {
        ...utxo,
        isOwner:
          utxo.type === 'WALLET' &&
          Address.fromBech32(utxo.utxoAddress).pubKeyHash.hex === pkh,
      };
    } catch (error) {
      return utxo;
    }
  });
};
const selectLoading = (store: MarketStore) => store.loading;
const selectMarketAsset = (store: MarketStore) => ({
  policy: store.policy,
  tokenName: store.tokenName,
  id: store.assetId,
  assetUrl: store.assetUrl,
  thumbnailB64: store.thumbnailB64,
  title: store.title,
});
const selectWallet = (store: MarketStore) => store.wallet;
const selectCurrentOwner = (store: MarketStore) => store.currentOwner;
const selectReload = (store: MarketStore) => store.reload;
const selectSetWallet = (store: MarketStore) => store.setWallet;
const selectPerformTransaction = (store: MarketStore) =>
  store.performTransaction;
const selectPerformListing = (store: MarketStore) => store.performListing;
const selectDoListing = (store: MarketStore) => store.doListing;
const selectError = (store: MarketStore) => store.error;
const selectClearError = (store: MarketStore) => store.clearError;
const selectCloseActionModal = (store: MarketStore) => store.closeActionModal;
const selectAction = (store: MarketStore) => store.action;
const selectActionModalStatus = (store: MarketStore) => store.actionModalStatus;
const selectTxState = (store: MarketStore) => store.txState;
const selectAwaitingSignature = (store: MarketStore) => store.awaitingSignature;
const selectTxInProgress = (store: MarketStore) => store.txInProgress;
const selectDelistToDs = (store: MarketStore) => store.delistToDs;

export const useMarketProjectRoyalties = () =>
  useStore(useMarketStore(), selectRoyaltyItems);
export const useMarketCurrentListing = () =>
  useStore(useMarketStore(), selectCurrentListing);
export const useMarketUtxos = () => useStore(useMarketStore(), selectUtxos);
export const useMarketLoading = () => useStore(useMarketStore(), selectLoading);
export const useMarketAsset = () =>
  useStore(useMarketStore(), selectMarketAsset);

export const useMarketWallet = () => useStore(useMarketStore(), selectWallet);
export const useMarketCurrentOwner = () =>
  useStore(useMarketStore(), selectCurrentOwner);
export const useMarketReload = () => useStore(useMarketStore(), selectReload);
export const useMarketSetWallet = () =>
  useStore(useMarketStore(), selectSetWallet);
export const useMarketPerformTransaction = () =>
  useStore(useMarketStore(), selectPerformTransaction);
export const useMarketPerformListing = () =>
  useStore(useMarketStore(), selectPerformListing);
export const useMarketDoListing = () =>
  useStore(useMarketStore(), selectDoListing);
export const useMarketError = () => useStore(useMarketStore(), selectError);
export const useMarketClearError = () =>
  useStore(useMarketStore(), selectClearError);
export const useMarketCloseActionModal = () =>
  useStore(useMarketStore(), selectCloseActionModal);
export const useMarketAction = () => useStore(useMarketStore(), selectAction);
export const useMarketActionModalStatus = () =>
  useStore(useMarketStore(), selectActionModalStatus);
export const useMarketTxState = () => useStore(useMarketStore(), selectTxState);
export const useMarketAwaitingSignature = () =>
  useStore(useMarketStore(), selectAwaitingSignature);
export const useMarketTxInProgress = () =>
  useStore(useMarketStore(), selectTxInProgress);
export const useMarketDelistToDs = () =>
  useStore(useMarketStore(), selectDelistToDs);
/** End Hooks */

export const AssetPageMarketContextWrapper = React.memo(
  AssetPageMarketContextWrapper_,
  (prev, next) => {
    return (
      prev.asset.id === next.asset.id &&
      prev.asset.assetUrl === next.asset.assetUrl
    );
  }
);
