import { AuthAction, withAuthUser } from 'next-firebase-auth';
import React, { ComponentType, FC, useEffect, useState } from 'react';
import { NativeAssetRankings } from '#types/index';
import Head from 'next/head';
import { getFirebaseAdmin } from 'next-firebase-auth';
import { useCoinGecko } from '#lib/hooks/useCoinGecko';
import cn from 'classnames';
import { useDegenStore } from '#lib/hooks/useGlobalMode';

type TrendingProps = {
  trending_24Hours?: NativeAssetRankings[];
  trending_7Day?: NativeAssetRankings[];
  trending_30Day?: NativeAssetRankings[];
  trending_All?: NativeAssetRankings[];
};

type SortField = {
  fieldName: string;
  desc: boolean;
};

const Home: FC<TrendingProps> = ({
  trending_24Hours,
  trending_7Day,
  trending_30Day,
  trending_All,
}: TrendingProps) => {
  const mode = useDegenStore((state) => state.degenMode);

  return (
    <Layout preview={false} selectedSubMenuIndex={2}>
      <header>
        <Head>
          <title>Trending | Dropspot</title>
        </Head>
      </header>
      <div
        className={cn(
          'flex flex-col flex-1 h-full w-full bg-gray-100 dark:bg-base-200 pt-[6rem] px-3 sm:px-6 xl:px-12',
          { 'border-t dark:border-gray-700': mode }
        )}
      >
        <div className={cn('')}>
          <h1 className="text-gray-800 dark:text-white text-3xl font-extrabold ">
            Trending by Volume
          </h1>
        </div>
        <div className="pt-6">
          {!!trending_24Hours && (
            <MarketTop12
              rankings24H={trending_24Hours}
              rankings7D={trending_7Day}
              rankings30D={trending_30Day}
              rankingsALL={trending_All}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export type RankingProps = {
  mode?: 'TABLE' | 'GRID';
  rankings24H?: NativeAssetRankings[];
  rankings7D?: NativeAssetRankings[];
  rankings30D?: NativeAssetRankings[];
  rankingsALL?: NativeAssetRankings[];
};

export const MarketTop12 = ({
  rankings24H,
  rankings7D,
  rankings30D,
  rankingsALL,
  mode = 'TABLE',
}: RankingProps) => {
  const [currentDisplaySet, setCurrentDisplaySet] = useState(rankings24H);
  const [filteredDisplaySet, setFilteredDisplaySet] = useState(rankings24H);
  const { coinGeckoPrice } = useCoinGecko();
  const [filter, setFilter] = useState<string>();
  const [sortField, setSortField] = useState<SortField>();

  const sortTable = (fieldName: string) => {
    if (!sortField) {
      setSortField({ fieldName: fieldName, desc: true });
    } else {
      if (fieldName == sortField.fieldName) {
        setSortField({
          ...sortField,
          desc: sortField.desc == true ? false : true,
        });
      } else {
        setSortField({ ...sortField, fieldName: fieldName, desc: true });
      }
    }
  };
  const { defaultCountry } = useIpCountry();

  useEffect(() => {
    if (sortField?.fieldName == 'trades') {
      setFilteredDisplaySet((f) =>
        f?.sort((a, b) => {
          if (sortField.desc == true) {
            return b.sales - a.sales;
          } else {
            return a.sales - b.sales;
          }
        })
      );
    }
    if (sortField?.fieldName == 'floor') {
      setFilteredDisplaySet((f) =>
        f?.sort((a, b) => {
          if (sortField.desc == true) {
            return b.price - a.price;
          } else {
            return a.price - b.price;
          }
        })
      );
    }

    // if (sortField?.fieldName == 'volume7d') {
    //   setFilteredDisplaySet((f) =>
    //     f?.sort((a, b) => {
    //       if (sortField.desc == true) {
    //         return b.volume7 - a.volume7;
    //       } else {
    //         return a.volume7 - b.volume7;
    //       }
    //     })
    //   );
    // }

    // if (sortField?.fieldName == 'volume24h') {
    //   setFilteredDisplaySet((f) =>
    //     f?.sort((a, b) => {
    //       if (!sortField.desc) {
    //         return a.volume24 - b.volume24;
    //       } else {
    //         return b.volume24 - a.volume24;
    //       }
    //     })
    //   );
    // }
  }, [sortField]);

  console.log('filteredDisplaySet', filteredDisplaySet, rankings7D);

  const router = useRouter();

  const selectedTimeframe = router.query.timeframe as
    | '24H'
    | '7D'
    | '30D'
    | 'ALL'
    | undefined;
  const [timeView, setTimeView] = useState<'24H' | '7D' | '30D' | 'ALL'>(
    selectedTimeframe || '24H'
  );

  useEffect(() => {
    router.replace({ query: { ...router.query, timeframe: timeView } });
  }, [timeView]);

  return (
    <>
      {!!rankings24H && (
        <>
          {mode == 'TABLE' && (
            <div className="flex flex-col w-full">
              <div className="z-[6] top-2 flex flex-col-reverse sm:flex-row sm:gap-12 justify-between items-center sm:items-center w-full ">
                <DaisyTextInput
                  id={''}
                  label={''}
                  className="w-96 sm:max-w-[384px] flex"
                  rounded={true}
                  placeholder={'Search by collections'}
                  specialIcon={
                    <MagnifyingGlassIcon className="w-5 h-5 text-gray-500" />
                  }
                  value={filter}
                  onChange={function (text: string): void {
                    if (!!text && text.length > 0) {
                      setFilteredDisplaySet(
                        currentDisplaySet?.filter((asset) =>
                          asset.name.toLowerCase().includes(text.toLowerCase())
                        )
                      );
                    } else {
                      setFilteredDisplaySet(currentDisplaySet);
                    }
                    setFilter(text);
                  }}
                />
                <div className="btn-group lg:min-w-[28rem] flex justify- sm:justify-end">
                  <button
                    className={`btn btn-md px-2 sm:px-4 ${
                      timeView == '24H' && 'btn-active'
                    } `}
                    onClick={() => {
                      setTimeView('24H');
                      setCurrentDisplaySet(rankings24H);
                      setFilteredDisplaySet(rankings24H);
                      setFilter('');
                    }}
                  >
                    24hrs
                  </button>
                  <button
                    className={` btn btn-md px-2 sm:px-4 ${
                      timeView == '7D' && 'btn-active'
                    } `}
                    onClick={() => {
                      setTimeView('7D');
                      setCurrentDisplaySet(rankings7D);
                      setFilteredDisplaySet(rankings7D);
                      setFilter('');
                    }}
                  >
                    7days
                  </button>
                  <button
                    className={` btn btn-md px-2 sm:px-4 ${
                      timeView == '30D' && 'btn-active'
                    } `}
                    onClick={() => {
                      setTimeView('30D');
                      setCurrentDisplaySet(rankings30D);
                      setFilteredDisplaySet(rankings30D);
                      setFilter('');
                    }}
                  >
                    30days
                  </button>
                  <button
                    className={` btn btn-md px-2 sm:px-4 ${
                      timeView == 'ALL' && 'btn-active'
                    } `}
                    onClick={() => {
                      setTimeView('ALL');
                      setCurrentDisplaySet(rankingsALL);
                      setFilteredDisplaySet(rankingsALL);
                      setFilter('');
                    }}
                  >
                    All Time
                  </button>
                </div>
                <div className="hidden  flex-row flex-none justify-end min-w-[28rem] btn-group">
                  <button
                    type="button"
                    onClick={() => {
                      setTimeView('24H');
                      setCurrentDisplaySet(rankings24H);
                      setFilteredDisplaySet(rankings24H);
                      setFilter('');
                    }}
                    className="text-xs  sm:text-base relative inline-flex items-center px-2 sm:px-6 py-2 rounded-l-full border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-900 font-light text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500"
                  >
                    <span className="sr-only">1 Day</span>24 hrs
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setTimeView('7D');
                      setCurrentDisplaySet(rankings7D);
                      setFilteredDisplaySet(rankings7D);
                      setFilter('');
                    }}
                    className="text-xs sm:text-base -ml-px relative inline-flex items-center px-2 sm:px-6 py-2 border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-900 font-light text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500"
                  >
                    <span className="sr-only">7 Day</span>7 days
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setTimeView('30D');
                      setCurrentDisplaySet(rankings30D);
                      setFilteredDisplaySet(rankings30D);
                      setFilter('');
                    }}
                    className="text-xs sm:text-base -ml-px relative inline-flex items-center px-2 sm:px-6 py-2  border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-900 font-light text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500"
                  >
                    <span className="sr-only">7 Day</span>30 days
                  </button>

                  <button
                    type="button"
                    onClick={() => {
                      setTimeView('ALL');
                      setCurrentDisplaySet(rankingsALL);
                      setFilteredDisplaySet(rankingsALL);
                      setFilter('');
                    }}
                    className="text-xs sm:text-base -ml-px relative inline-flex items-center px-2 sm:px-6 py-2 rounded-r-full border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-900 font-light text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500"
                  >
                    <span className="sr-only">All time</span>All time
                  </button>
                </div>
              </div>
              <div className="flex flex-row w-full font-bold  dark:text-gray-300 h-full items-center mt-4 px-4">
                <div className="text-sm sm:text-lg text-gray-600 dark:text-gray-300 flex-1">
                  Collection
                </div>
                {/* <div className="text-lg text-gray-600  dark:text-gray-300 flex-initial w-44  items-end justify-center flex text-right">
                Owners
              </div> */}
                <div className="text-sm sm:text-lg  hidden lg:flex text-gray-600 dark:text-gray-300 flex-initial w-44  items-end justify-center    text-right">
                  <a
                    className="cursor-pointer flex gap-2"
                    onClick={() => sortTable('trades')}
                  >
                    Trades
                    {sortField?.fieldName === 'trades' && !sortField.desc && (
                      <ChevronDownIcon className="w-4" />
                    )}
                    {sortField?.fieldName === 'trades' && !!sortField.desc && (
                      <ChevronUpIcon className="w-4" />
                    )}
                  </a>
                </div>

                <div className="text-sm sm:text-lg  text-gray-600 dark:text-gray-300 flex-initial w-24 sm:w-32 md:w-44 text-right items-end justify-end hidden sm:flex ">
                  <a
                    className="cursor-pointer flex gap-2"
                    onClick={() => sortTable('floor')}
                  >
                    Floor
                    {sortField?.fieldName === 'floor' && !sortField.desc && (
                      <ChevronDownIcon className="w-4" />
                    )}
                    {sortField?.fieldName === 'floor' && !!sortField.desc && (
                      <ChevronUpIcon className="w-4" />
                    )}
                  </a>
                </div>

                <div className="hidden xl:flex flex-initial text-gray-600 dark:text-gray-300 w-24 sm:w-32 md:w-52 items-center justify-end">
                  <a
                    className="cursor-pointer flex gap-2"
                    onClick={() => sortTable('volume24h')}
                  >
                    Volume: 24 hrs
                    {sortField?.fieldName === 'volume24h' &&
                      !sortField.desc && <ChevronDownIcon className="w-4" />}
                    {sortField?.fieldName === 'volume24h' &&
                      !!sortField.desc && <ChevronUpIcon className="w-4" />}
                  </a>
                </div>

                <div className="hidden xl:flex flex-initial text-gray-600 dark:text-gray-300 w-24 sm:w-32 md:w-52 items-center justify-end">
                  <a
                    className="cursor-pointer flex gap-2"
                    onClick={() => sortTable('volume24h')}
                  >
                    Volume: 7 day
                    {sortField?.fieldName === 'volume24h' &&
                      !sortField.desc && <ChevronDownIcon className="w-4" />}
                    {sortField?.fieldName === 'volume24h' &&
                      !!sortField.desc && <ChevronUpIcon className="w-4" />}
                  </a>
                </div>
                <div className="text-sm sm:text-lg flex-initial text-gray-600 dark:text-gray-300 w-24 sm:w-32 md:w-52  items-end justify-end flex text-right pr-2">
                  <a
                    className="cursor-pointer flex gap-2"
                    onClick={() => sortTable('volume7d')}
                  >
                    Volume
                    {sortField?.fieldName === 'volume7d' && !sortField.desc && (
                      <ChevronDownIcon className="w-4" />
                    )}
                    {sortField?.fieldName === 'volume7d' &&
                      !!sortField.desc && <ChevronUpIcon className="w-4" />}
                  </a>
                </div>
              </div>

              {filteredDisplaySet?.map((ranking, idx) => (
                <Link
                  key={idx}
                  href={
                    ranking.path
                      ? ranking.path.replace('collections', 'marketplace')
                      : ''
                  }
                >
                  <a
                    className={`${
                      ranking.path ? 'cursor-pointer' : 'cursor-not-allowed'
                    }`}
                  >
                    <div className="flex items-center justify-start w-full mb-3 bg-gray-50 dark:bg-gray-900/50 dark:text-white py-4 px-1 sm:px-4 rounded-lg shadow-md hover:shadow-lg hover:bg-white dark:hover:bg-gray-900 ">
                      <div className="flex-initial pl-3 ">
                        {!!ranking.featureImage && (
                          <div className="rounded-full overflow-hidden h-16 w-16 sm:h-16 sm:w-16 ">
                            <ImageSwap
                              src={ranking.featureImage}
                              alt={''}
                              thumb={ranking.featureImageB64}
                            />
                          </div>
                        )}
                        {!ranking.featureImage && ranking.logo && (
                          <div className="rounded-full overflow-hidden h-16 w-16 sm:h-16 sm:w-16 ">
                            <img
                              className="w-full h-full rounded-full object-cover shadow-2xl"
                              src={ranking.logo
                                .toString()
                                .replace(
                                  'ipfs://',
                                  'https://cloudflare-ipfs.com/ipfs/'
                                )}
                              alt=""
                            />
                          </div>
                        )}
                      </div>

                      <div className="text-base sm:text-lg lg:text-xl font-bold flex-1 flex flex-col justify-center sm:ml-4 ml-2">
                        {ranking.name}
                        <div className="text-base hidden sm:flex w-max  text-gray-600 dark:text-gray-400 font-normal">
                          Minted: {ranking.supply}
                        </div>
                        <div className="text-base sm:hidden  text-gray-600 dark:text-gray-400 font-normal">
                          Floor: ₳
                          {ranking.price ? ranking.price?.toFixed(0) : 0}
                        </div>
                      </div>

                      <div className="text-lg flex-initial w-44 items-center justify-center hidden lg:flex ">
                        {ranking.sales}
                      </div>
                      <div className="hidden sm:flex text-lg lg:text-2xl font-bold w-44 text-right flex-initial items-end justify-center flex-col">
                        <p>₳ {ranking.price ? ranking.price?.toFixed(0) : 0}</p>
                        <p className="text-sm font-light text-gray-600 dark:text-gray-400 ">
                          {!!coinGeckoPrice && (
                            <>
                              $
                              {(
                                ranking.price /
                                (getExchangeRate(
                                  defaultCountry ?? 'US',
                                  coinGeckoPrice
                                ) ?? 0.0)
                              ).toFixed(0)}
                              <span className="font-light text-gray-700 dark:text-gray-400 text-sm"></span>
                            </>
                          )}
                          {!coinGeckoPrice && <>$</>}
                        </p>
                      </div>

                      <div className="hidden xl:flex">
                        <div className="text-lg flex-initial w-24 sm:w-32 md:w-52 items-end justify-center flex flex-col">
                          <p className="text-lg lg:text-2xl font-bold">
                            ₳ {(ranking.volume24 / 1000).toFixed(0)}
                            <span className="font-light text-gray-700 dark:text-gray-300 text-lg">
                              k
                            </span>
                          </p>
                          <div className="flex flex-row gap-1 justify-center items-center">
                            {ranking['oneDayChange'] > 0 && (
                              <>
                                <ArrowTrendingUpIcon className="text-green-500 h-4 w-4" />
                                <p className="text-green-500 text-sm">
                                  {' '}
                                  {(ranking['oneDayChange'] * 100).toFixed(0)}%
                                </p>
                              </>
                            )}
                            {ranking['oneDayChange'] < 0 && (
                              <>
                                <ArrowTrendingDownIcon className="text-red-500 h-4 w-4" />
                                <p className="text-red-500 text-sm">
                                  {' '}
                                  {(ranking['oneDayChange'] * 100).toFixed(0)}%
                                </p>
                              </>
                            )}
                            <p className="text-sm text-gray-600 dark:text-gray-400 ">
                              {!!coinGeckoPrice && (
                                <>
                                  $
                                  {(
                                    (ranking.volume24 / 1000) *
                                    (getExchangeRate(
                                      defaultCountry ?? 'US',
                                      coinGeckoPrice
                                    ) ?? 0.0)
                                  ).toFixed(0)}
                                  <span className="font-light text-gray-700 dark:text-gray-400 text-sm">
                                    k
                                  </span>
                                </>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="hidden xl:flex">
                        <div className="text-lg flex-initial w-24 sm:w-32 md:w-52 items-end justify-center flex flex-col">
                          <p className="text-lg lg:text-2xl font-bold">
                            ₳ {(ranking.volume7 / 1000).toFixed(0)}
                            <span className="font-light text-gray-700 dark:text-gray-300 text-lg">
                              k
                            </span>
                          </p>
                          <div className="flex flex-row gap-1 justify-center items-center">
                            {ranking['sevenDayChange'] > 0 && (
                              <>
                                <ArrowTrendingUpIcon className="text-green-500 h-4 w-4" />
                                <p className="text-green-500 text-sm">
                                  {' '}
                                  {(ranking['sevenDayChange'] * 100).toFixed(0)}
                                  %
                                </p>
                              </>
                            )}
                            {ranking['sevenDayChange'] < 0 && (
                              <>
                                <ArrowTrendingDownIcon className="text-red-500 h-4 w-4" />
                                <p className="text-red-500 text-sm">
                                  {' '}
                                  {(ranking['sevenDayChange'] * 100).toFixed(0)}
                                  %
                                </p>
                              </>
                            )}
                            <p className="text-sm text-gray-600 dark:text-gray-400 ">
                              {!!coinGeckoPrice && (
                                <>
                                  $
                                  {(
                                    (ranking.volume7 / 1000) *
                                    (getExchangeRate(
                                      defaultCountry ?? 'US',
                                      coinGeckoPrice
                                    ) ?? 0.0)
                                  ).toFixed(0)}
                                  <span className="font-light text-gray-700 dark:text-gray-400 text-sm">
                                    k
                                  </span>
                                </>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="flex pr-2">
                        <div className="text-lg flex-initial w-24 sm:w-32 md:w-52 items-end justify-center flex flex-col">
                          <p className="text-lg lg:text-2xl font-bold">
                            ₳ {(ranking.volume / 1000).toFixed(0)}
                            <span className="font-light text-gray-700 dark:text-gray-300  text-lg">
                              k
                            </span>
                          </p>
                          <div className="flex flex-row gap-1 justify-center items-center">
                            {ranking['volumePctChg'] > 0 && (
                              <>
                                <ArrowTrendingUpIcon className="text-green-500 h-4 w-4" />
                                <p className="text-green-500 text-sm">
                                  {' '}
                                  {(ranking['volumePctChg'] * 100).toFixed(0)}%
                                </p>
                              </>
                            )}
                            {ranking['volumePctChg'] < 0 && (
                              <>
                                <ArrowTrendingDownIcon className="text-red-500 h-4 w-4" />
                                <p className="text-red-500 text-sm">
                                  {' '}
                                  {(ranking['volumePctChg'] * 100).toFixed(0)}%
                                </p>
                              </>
                            )}
                            <p className="text-sm text-gray-600 dark:text-gray-400">
                              {!!coinGeckoPrice && (
                                <>
                                  $
                                  {(
                                    (ranking.volume / 1000) *
                                    (getExchangeRate(
                                      defaultCountry ?? 'US',
                                      coinGeckoPrice
                                    ) ?? 0.0)
                                  ).toFixed(0)}
                                  <span className="font-light text-gray-700  dark:text-gray-400 text-sm">
                                    k
                                  </span>
                                </>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </Link>
              ))}
              <ScrollToTopButton />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default withAuthUser({
  whenAuthed: AuthAction.RENDER,
  whenUnauthedBeforeInit: AuthAction.SHOW_LOADER,
  whenUnauthedAfterInit: AuthAction.RENDER,
  LoaderComponent: function FullPageLoader() {
    return <Loading />;
  },
})(Home as ComponentType<unknown>);

export const getStaticProps = async () => {
  const result = await getHomePage();

  return {
    props: { ...result },
  };
};

import Link from 'next/link';
import {
  getTop100_24HR,
  getTop100_30D,
  getTop100_7D,
  getTop100_All,
} from '#lib/firestore';
import { Loading } from '#components/Loading/Loading';
import {
  ArrowDownCircleIcon,
  ArrowTrendingDownIcon,
  ArrowTrendingUpIcon,
  ArrowUpCircleIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/solid';
import { TextInput } from '#components/Inputs/TextInput/TextInput';
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import { Divider } from '#components/Divider/Divider';
import { ImageSwap } from '#components/Assets/DSImage';
import Layout from '#components/TraderDashboard/Layout';
import { DaisyTextInput } from '#components/Daisy/DaisyTextInput';
import { useRouter } from 'next/router';
import ScrollToTopButton from '#components/Button/scrollToTop';
import {
  countryToCurrency,
  getExchangeRate,
} from '#components/Assets/AssetPage/ChainInteraction/utils';
import { useIpCountry } from '#lib/hooks/useIpCountry';

export const getHomePage: () => Promise<TrendingProps> = async () => {
  const homeProps: TrendingProps = {};

  const fbAdmin = getFirebaseAdmin();

  if (!fbAdmin) {
    throw new Error('FB Admin init error');
  }

  const top100_24H = await getTop100_24HR();
  const top100_7D = await getTop100_7D();
  const top100_30D = await getTop100_30D();
  const top100_ALL = await getTop100_All();

  homeProps.trending_24Hours = top100_24H.slice(0, 99);
  homeProps.trending_7Day = top100_7D.slice(0, 99);
  homeProps.trending_30Day = top100_30D.slice(0, 99);
  homeProps.trending_All = top100_ALL.slice(0, 99);

  return homeProps;
};
